import React from 'react'
import styles from './StepsSection.module.css'
import Container from '../../components/Container'

export default () => {
  const Steps = [
    {
      icon: require('./icons/delivery.svg'),
      name: 'Приносите неисправное оборудывание'
    },
    {
      icon: require('./icons/diagnostic.svg'),
      name: 'Проводим диагностику'
    },
    {
      icon: require('./icons/question-steps.svg'),
      name: 'Согласовываем стоимость'
    },
    {
      icon: require('./icons/repair.svg'),
      name: 'Устраняем неисправность'
    },
    {
      icon: require('./icons/computer.svg'),
      name: 'Оплачиваете и забираете исправное устройство'
    }
  ]
  return (
    <div className={styles['StepsSection']}>
      <Container className={styles['StepsSection__Inner']}>
        <h2>Быстро и просто</h2>
        <p>От поломки до исправного устройства всего за 5 шагов</p>
        <ul className={styles['StepsSection__List']}>
          {Steps.map(step =>
            <li className={styles['StepsSection__Item']} key={step.name}>
              <img className={styles['StepsSection__Image']} src={step.icon} alt={step.name} />
              <p className={styles['StepsSection__Title']}>{step.name}</p>
            </li>
          )}
        </ul>
      </Container>
    </div>
  )
}
