import React from 'react'
import Container from '../../components/Container'
import styles from './CertificatesSection.module.css'

export default () => {
  const Certificates = [
    {
      title: 'Сертификат Dr.Web',
      url: require('./certificates/drweb.jpg')
    },
    {
      title: 'Сертификат Kerher',
      url: require('./certificates/kerher.jpg')
    },
    {
      title: 'Сертификат Philips',
      url: require('./certificates/philips.jpg')
    }
  ]
  return (
    <div className={styles['CertificatesSection']}>
      <Container className={styles['CertificatesSection__Inner']}>
        <h2>Наше качество подтвердили мировые фирмы</h2>
        <ul className={styles['CertificatesSection__List']}>
          {Certificates.map(image =>
            <li className={styles['CertificatesSection__Item']} key={image.title}>
              <img className={styles['CertificatesSection__Image']} src={image.url} alt={image.title} />
            </li>
          )}
        </ul>
      </Container>
    </div>
  )
}
