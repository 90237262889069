import React from 'react'
import Container from '../../components/Container'
import styles from './Banner.module.css'
import image from './Banner.png'

export default () => {
  return (
    <div className={styles['Banner']}>
      <Container className={styles['Banner__Inner']}>
        <div className={styles['Banner__Content']}>
          <h1>Качество вне времени</h1>
          <p>Чистим, настраиваем, ремонтируем и продаем компьютеры, ноутбуки, планшеты, мониторы, видеокарты и другие комплектующие ежедневно с 2005 года.</p>
          <p>Обеспечиваем клиентам техническую поддержку, чтобы они пользовались компьютерами в любое время дня и ночи, будь то игры и проведение досуга, общение с родными и близкими, работа или учёба.</p>
        </div>
        <img className={styles['Banner__Image']} src={image} />
      </Container>
    </div>
  )
}
