import React from 'react'
import { Helmet } from 'react-helmet'

import Layout from '../components/Layout.jsx'
import Banner from '../components/Banner'
import StepsSection from '../components/StepsSection'
import WorksSection from '../components/WorksSection'
import ReviewsSection from '../components/ReviewsSection'
import CertificatesSection from '../components/CertificatesSection'

export default () => (
  <Layout>
    <Helmet>
      <title>АлексКомп — компьютерная помощь в Павлограде</title>
      <meta name="description" content="Чистим, настраиваем, ремонтируем и продаем компьютеры, ноутбуки, планшеты, мониторы, видеокарты и другие комплектующие в Павлограде." />
    </Helmet>
    <Banner />
    <StepsSection />
    <WorksSection />
    <ReviewsSection />
    <CertificatesSection />
  </Layout>
)
