import React from 'react'
import styles from './WorksSection.module.css'
import Container from '../../components/Container'

export default () => {
  const Works = [
    {
      icon: require('./icons/windows.svg'),
      name: 'Полная настройка Windows',
      price: 'От 200 грн.'
    },
    {
      icon: require('./icons/clean.svg'),
      name: 'Чистка компьютера, замена термопасты',
      price: 'От 200 грн.'
    },
    {
      icon: require('./icons/cable.svg'),
      name: 'Замена кабеля зарядного устройства ноутбука',
      price: 'От 250 грн.'
    }
  ]
  return (
    <div className={styles['WorksSection']}>
      <Container className={styles['WorksSection__Inner']}>
        <h2>Честная работа по доступной цене</h2>
        <p>Стоимость популярных работ по ремонту:</p>
        <ul className={styles['WorksSection__List']}>
          {Works.map(work =>
            <li className={styles['WorksSection__Item']} key={work.name}>
              <img className={styles['WorksSection__Image']} src={work.icon} alt={work.name} />
              <h3 className={styles['WorksSection__Title']}>{work.name}</h3>
              <p className={styles['WorksSection__Price']}>{work.price}</p>
            </li>
          )}
        </ul>
      </Container>
    </div>
  )
}
