import React from 'react'
import Link from '../../components/Link'
import styles from './ReviewsSection.module.css'
import Container from '../../components/Container'

export default () => {
  const Reviews = [
    {
      avatar: require('./avatars/slavik.jpg'),
      name: 'Славик Ананченко',
      link: 'http://vk.com/alexcomp_pavlograd?w=wall-116309040_129',
      comment: 'Спасибо AlexCompy. Все сделали грамотно.Почистили ноут теперь не греется)))) Рекомендую.'
    },
    {
      avatar: require('./avatars/pasha.jpg'),
      name: 'Павел Станишевский',
      link: 'http://vk.com/alexcomp_pavlograd?w=wall-116309040_252',
      comment: 'Ноут теперь работает очень быстро. В общем работой специалистов в АлексКомп очень доволен.'
    }
  ]
  return (
    <div className={styles['ReviewsSection']}>
      <Container className={styles['ReviewsSection__Inner']}>
        <h2 className={styles['ReviewsSection__Title']}>Отзывы наших клиентов</h2>
        <ul className={styles['ReviewsSection__List']}>
          {Reviews.map(review =>
            <li className={styles['ReviewsSection__Item']} key={review.link}>
              <img src={review.avatar} alt={review.name} className={styles['ReviewsSection__Avatar']} />
              <div className={styles['ReviewsSection__Content']}>
                <Link simple to={review.link} className={styles['ReviewsSection__Name']}>{review.name}</Link>
                <p className={styles['ReviewsSection__Comment']}>{review.comment}</p>
              </div>
            </li>
          )}
        </ul>
      </Container>
    </div>
  )
}
